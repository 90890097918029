.product-dashboard-header-container .header-logo {
  width: 120px;
  margin-top: 5px; }

.product-dashboard-header-container .product-dashboard-header-logo-container {
  display: inline-block !important; }

.product-dashboard-header-container .menu {
  float: right; }
  .product-dashboard-header-container .menu .menu-item {
    margin: 20px;
    color: #fff;
    transition: all .25s ease-in-out;
    display: inline-block; }
  .product-dashboard-header-container .menu .menu-item:hover {
    color: #fff; }

.active .product-dashboard-header-container .menu .menu-item {
  color: #222; }

.menu-icon-mobile {
  float: right;
  margin: 7px 10px 0px 0px; }
  .menu-icon-mobile .material-icons {
    opacity: .3;
    transition: all .25s ease-in-out; }

.menu-icon-mobile:hover .material-icons, .menu-icon-mobile:active .material-icons {
  opacity: 1;
  transition: all .25s ease-in-out; }

.design-header-container.active .header-cta {
  opacity: 1;
  transform: translateY(0%); }

.header-container.active .search-bar .dark-transparent-input::placeholder {
  color: #666; }

.header-container.active .search-bar .material-icons {
  color: #666 !important; }

.header-container.active .search-bar .search-bar-background {
  background-color: #f1f1f1 !important; }

.coverage-summary-print-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px; }

@media only screen and (max-width: 800px) {
  .design-site-mobile-hero-padding {
    padding-top: 120px;
    padding-bottom: 30px; }
  .product-dashboard-header-container .search-bar {
    width: 100%;
    padding: 10px; }
    .product-dashboard-header-container .search-bar .material-icons {
      font-size: 24px !important; }
    .product-dashboard-header-container .search-bar .dark-transparent-input {
      font-size: 16px; }
  .product-dashboard-header-container .menu {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center; }
    .product-dashboard-header-container .menu .menu-item {
      margin: 10px; }
  .product-dashboard-header-container .product-dashboard-header-logo-container {
    display: flex !important;
    align-items: center;
    justify-content: center; }
  .product-dashboard-header-container .header-cta {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateY(-10%); }
  .product-dashboard-header-container .header-cta a {
    padding: 10px 30px; } }
