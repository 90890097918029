.text-over-image-block {
  position: relative;
  width: 100%;
  height: 100%; }
  .text-over-image-block .image-block-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    min-height: 50vh;
    display: flex;
    align-items: center; }
    .text-over-image-block .image-block-overlay h3, .text-over-image-block .image-block-overlay p {
      color: #fff; }
  .text-over-image-block .image-block-overlay-color {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1; }
  .text-over-image-block .image-block-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-position: center; }
