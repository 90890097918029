.design-header-container .header-logo {
  width: 120px;
  margin-top: 5px; }

.design-header-container .design-header-logo-container {
  display: inline-block;
  margin-left: 20px; }

.design-header-container .menu {
  float: right; }
  .design-header-container .menu .menu-item {
    margin: 20px;
    color: #fff;
    transition: all .25s ease-in-out;
    display: inline-block;
    opacity: .75; }
  .design-header-container .menu .menu-item:hover {
    color: #fff; }
  .design-header-container .menu .active .menu-item {
    color: #fff; }

.design-header-container.light .header-logo.dark {
  display: none; }

.design-header-container.light .header-logo.light {
  display: block;
  opacity: 1; }

.design-header-container.light .material-icons.dark {
  display: none; }

.design-header-container.light .material-icons.light {
  display: block;
  opacity: 1; }

.design-header-container .header-logo.dark, .design-header-container.active .header-logo.dark, .design-header-container.light.active .header-logo.dark {
  display: block; }

.design-header-container .header-logo.light, .design-header-container.active .header-logo.light, .design-header-container.light.active .header-logo.light {
  display: none; }

.design-header-container .material-icons.dark, .design-header-container.active .material-icons.dark, .design-header-container.light.active .material-icons.dark {
  display: block; }

.design-header-container .material-icons.light, .design-header-container.active .material-icons.light, .design-header-container.light.active .material-icons.light {
  display: none; }

.design-header-container .header-logo.dark {
  display: none; }

.design-header-container .header-logo.light {
  display: block; }

.menu-icon-mobile {
  float: right;
  margin: 7px 10px 0px 0px; }
  .menu-icon-mobile .material-icons {
    opacity: .3;
    transition: all .25s ease-in-out; }

.menu-icon-mobile:hover .material-icons, .menu-icon-mobile:active .material-icons {
  opacity: 1;
  transition: all .25s ease-in-out; }

.design-header-container.active .menu .menu-item {
  color: #8C8C8C; }

.design-header-container.active .header-cta {
  opacity: 1;
  transform: translateY(0%); }

.design-header-container.active .design-header-logo-container {
  display: inline-block; }

@media only screen and (max-width: 800px) {
  .design-header-container .menu {
    display: none; }
  .design-header-container .header-cta {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateY(-10%); }
  .design-header-container .header-cta a {
    padding: 10px 30px; }
  .design-header-logo-container {
    display: inline-block;
    margin-left: 0px !important; }
  .design-header-container .design-header-logo-container {
    display: flex;
    align-items: center;
    justify-content: center; } }
