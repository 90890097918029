/* Hero Styling */
.modal-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 20000000; }

.modal-foreground-wrapper {
  z-index: 20000002;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }

.modal-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  z-index: 20000001; }

.modal {
  margin: auto;
  z-index: 20000002;
  width: 100vw;
  max-height: 100vh;
  overflow: scroll;
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }

.modal::-webkit-scrollbar {
  display: none; }

/* Hide scrollbar for IE, Edge and Firefox */
.modal {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }

.modal-content {
  position: relative;
  z-index: 20000003;
  pointer-events: auto;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh; }

.modal-content::-webkit-scrollbar {
  display: none; }

.modal-content.full-screen-override {
  max-width: none !important;
  width: 100vw;
  padding: 20px; }

/* Hide scrollbar for IE, Edge and Firefox */
.modal-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }

.modal-container .modal-background {
  background-color: rgba(0, 0, 0, 0.75); }

.modal-container .modal {
  opacity: 1;
  transform: translateY(0px); }

.modal-container.animate-out .modal-background {
  background-color: rgba(0, 0, 0, 0); }

.modal-container.animate-out .modal {
  opacity: 0;
  transform: translateY(40px); }

.modal-container.animate-in .modal-background {
  background-color: rgba(0, 0, 0, 0); }

.modal-container.animate-in .modal {
  opacity: 0;
  transform: translateY(40px); }

.portal-modal-close-icon {
  position: fixed;
  z-index: 10000002;
  top: 40px;
  right: 40px;
  cursor: pointer; }

@keyframes darkBackgroundAnimation {
  from {
    background-color: rgba(0, 0, 0, 0); }
  to {
    background-color: rgba(0, 0, 0, 0.25); } }

@keyframes lightBackgroundAnimation {
  from {
    background-color: rgba(255, 255, 255, 0); }
  to {
    background-color: rgba(255, 255, 255, 0.25); } }

@keyframes foregroundAnimation {
  from {
    opacity: 0;
    transform: translateY(20px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@media only screen and (max-width: 800px) {
  .modal-background.active {
    font-size: 50px; }
  .modal-content {
    max-height: 80vh; }
  .portal-modal-close-icon {
    top: 20px;
    z-index: 20000004;
    right: 20px;
    position: absolute;
    cursor: pointer; }
    .portal-modal-close-icon img {
      width: 30px;
      height: 30px; } }
