/* Hero Styling */
.image-upload-container {
  position: relative; }

.image-upload-container .image-upload-container-upload-button-container {
  opacity: 0;
  transition: all .25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.image-upload-container .button {
  color: #fff; }

.image-upload-container:hover .image-upload-container-upload-button-container {
  opacity: 1; }
