@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
.primary-color {
  color: #0D4753; }

.primary-background {
  background-color: #0D4753; }

.white {
  color: #fff; }

.white-background {
  background-color: #fff; }

.black {
  color: #000; }

.black-background {
  background-color: #000; }

.light-grey {
  color: #f9f9f9; }

.light-grey-background {
  background-color: #f9f9f9; }

.medium-grey-color {
  color: #8C8C8C; }

.medium-grey-background {
  background-color: #8C8C8C; }

.dark-grey-color {
  color: #222; }

.dark-grey-background {
  background-color: #222; }

.green-color {
  color: #4cd964; }

.green-background {
  background-color: #4cd964; }

.red-color {
  color: #ff2d55; }

.red-background {
  background-color: #ff2d55; }

.orange-color {
  color: #FD9326; }

.orange-background {
  background-color: #FD9326; }

.purple-color {
  color: #5C3BFA; }

.purple-background {
  background-color: #5C3BFA; }

/* Spacing */
.tiny-top-margin {
  margin-top: 10px; }

.small-top-margin {
  margin-top: 30px; }

.medium-top-margin {
  margin-top: 40px; }

.large-top-margin {
  margin-top: 50px; }

.extra-large-top-margin {
  margin-top: 70px; }

.tiny-bottom-margin {
  margin-bottom: 10px; }

.small-bottom-margin {
  margin-bottom: 30px; }

.medium-bottom-margin {
  margin-bottom: 40px; }

.large-bottom-margin {
  margin-bottom: 50px; }

.extra-large-bottom-margin {
  margin-bottom: 70px; }

.tiny-padding {
  padding: 10px; }

.small-padding {
  padding: 20px; }

.medium-padding {
  padding: 30px; }

.large-padding {
  padding: 40px; }

.extra-large-padding {
  padding: 50px; }

.section-padding {
  padding-top: 40px;
  padding-bottom: 40px; }

.no-margin {
  margin: 0 !important; }

.no-top-margin {
  margin-top: 0px !important; }

.no-bottom-margin {
  margin-bottom: 0px !important; }

.no-top-padding {
  padding-top: 0px !important; }

.no-bottom-padding {
  padding-bottom: 0px !important; }

.no-left-padding {
  padding-left: 0px !important; }

.no-right-padding {
  padding-right: 0px !important; }

.half-width {
  width: 50%; }

.three-quarter-width {
  width: 75%; }

.full-width {
  width: 100%; }

.full-height {
  height: 100%; }

.half-height {
  height: 50%; }

.three-quarter-height {
  height: 75%; }

.max-width-container {
  max-width: 1400px;
  margin: auto; }

.center-position {
  margin: auto; }

.small-max-width-container {
  margin: auto;
  max-width: 800px;
  width: 98%; }

.section-padding {
  padding-top: 10vh;
  padding-bottom: 10vh; }

.full-screen-container {
  min-height: 100vh;
  width: 100vw; }

@media only screen and (max-width: 800px) {
  .max-width-container {
    max-width: 98%; }
  .no-mobile-padding-top {
    padding-top: 0px; } }

@media only screen and (min-width: 800px) {
  .tiny-top-margin {
    margin-top: 20px; }
  .small-top-margin {
    margin-top: 40px; }
  .medium-top-margin {
    margin-top: 60px; }
  .large-top-margin {
    margin-top: 80px; }
  .extra-large-top-margin {
    margin-top: 100px; }
  .tiny-bottom-margin {
    margin-bottom: 20px; }
  .small-bottom-margin {
    margin-bottom: 30px; }
  .medium-bottom-margin {
    margin-bottom: 50px; }
  .large-bottom-margin {
    margin-bottom: 70px; }
  .extra-large-bottom-margin {
    margin-bottom: 100px; }
  .tiny-padding {
    padding: 20px; }
  .small-padding {
    padding: 30px; }
  .medium-padding {
    padding: 50px; }
  .large-padding {
    padding: 70px; }
  .extra-large-padding {
    padding: 100px; }
  .section-padding {
    padding-top: 100px;
    padding-bottom: 100px; }
  .max-width-container {
    max-width: 96%;
    margin: auto; } }

@media only screen and (min-width: 1400px) {
  .tiny-top-margin {
    margin-top: 20px; }
  .small-top-margin {
    margin-top: 50px; }
  .medium-top-margin {
    margin-top: 90px; }
  .large-top-margin {
    margin-top: 120px; }
  .extra-large-top-margin {
    margin-top: 200px; }
  .tiny-bottom-margin {
    margin-bottom: 20px; }
  .small-bottom-margin {
    margin-bottom: 50px; }
  .medium-bottom-margin {
    margin-bottom: 90px; }
  .large-bottom-margin {
    margin-bottom: 120px; }
  .extra-large-bottom-margin {
    margin-bottom: 200px; }
  .tiny-padding {
    padding: 20px; }
  .small-padding {
    padding: 40px; }
  .medium-padding {
    padding: 80px; }
  .large-padding {
    padding: 120px; }
  .extra-large-padding {
    padding: 200px; }
  .section-padding {
    padding-top: 150px;
    padding-bottom: 150px; }
  .max-width-container {
    max-width: 1400px;
    margin: auto; } }

@media only screen and (min-width: 2000px) {
  .max-width-container {
    max-width: 1800px; } }

/* Grid */
.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }

@media only screen {
  /* Grid */
  .small-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .small-2 {
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .small-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .small-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .small-5 {
    flex-basis: 41.666%;
    max-width: 41.666%; }
  .small-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .small-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .small-8 {
    flex-basis: 66.666%;
    max-width: 66.666%; }
  .small-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .small-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .small-11 {
    flex-basis: 91.666%;
    max-width: 91.666%; }
  .small-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 800px) {
  .medium-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .medium-2 {
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .medium-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .medium-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .medium-5 {
    flex-basis: 41.666%;
    max-width: 41.666%; }
  .medium-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .medium-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .medium-8 {
    flex-basis: 66.666%;
    max-width: 66.666%; }
  .medium-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .medium-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .medium-11 {
    flex-basis: 91.666%;
    max-width: 91.666%; }
  .medium-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1450px) {
  .large-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .large-2 {
    flex-basis: 16.666%;
    max-width: 16.666%; }
  .large-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .large-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .large-5 {
    flex-basis: 41.666%;
    max-width: 41.666%; }
  .large-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .large-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .large-8 {
    flex-basis: 66.666%;
    max-width: 66.666%; }
  .large-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .large-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .large-11 {
    flex-basis: 91.666%;
    max-width: 91.666%; }
  .large-12 {
    flex-basis: 100%;
    max-width: 100%; } }

/* Spacing */
html, body {
  font-size: 18px;
  margin: 0; }

p {
  font-size: 1em;
  margin: 0;
  line-height: 1.5; }

h6 {
  font-size: .9em;
  margin: 0; }

h5 {
  font-size: 1.4em;
  margin: 0; }

h4 {
  font-size: 1.9em; }

h3 {
  font-size: 2.82em; }

h2 {
  font-size: 3.98em; }

h1 {
  font-size: 6em; }

@media only screen and (max-width: 800px) {
  p {
    font-size: 1em; }
  h6 {
    font-size: .8em; }
  h5 {
    font-size: 1em; }
  h4 {
    font-size: 1.5em; }
  h3 {
    font-size: 1.8em; }
  h2 {
    font-size: 2em; }
  h1 {
    font-size: 2.25em; } }

.fadeInUp-enter {
  opacity: 0.01;
  transform: translateY(50px); }

.fadeInUp-enter.fadeInUp-enter-active {
  transition: opacity 250ms ease-in;
  opacity: 1; }

.fadeInUp-appear {
  opacity: 0.01; }

.fadeInUp-appear.fadeInUp-appear-active {
  opacity: 1;
  transition: opacity 250ms ease-in; }

.fadeInUp-leave {
  opacity: 1; }

.fadeInUp-leave.fadeInUp-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in; }

html,
body {
  font-family: 'Roboto', sans-serif; }

body {
  -webkit-overflow-scrolling: touch; }

html {
  box-sizing: border-box;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; }

*,
*:before,
*:after {
  box-sizing: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  white-space: normal;
  white-space: initial;
  margin: 0.5em 0;
  line-height: 1.3em; }

p {
  white-space: normal;
  white-space: initial; }

.uppercase {
  text-transform: uppercase; }

a {
  cursor: pointer; }

.modal-content {
  max-width: 1000px !important; }

html,
body,
#root,
.wrapper {
  display: block;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  background-color: #fff; }

main {
  width: 100%;
  overflow: scroll;
  align-self: flex-start;
  position: absolute;
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-top: 80px;
  top: 0; }

.subheading {
  opacity: 0.4; }

.background-image-fill {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.background-image-fit {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.flex {
  display: flex; }

.middle {
  align-items: center; }

.bottom {
  align-items: flex-end; }

.section-margin {
  margin-top: 5%; }

.button {
  display: inline-block;
  padding: 20px;
  padding-left: 60px;
  padding-right: 60px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out; }

.button:hover {
  transform: scale(0.97, 0.97); }

.button-brightness:hover {
  filter: brightness(0.85);
  transition: all 0.25s ease-in-out; }

/* terms and conditions styling */
.terms-wrapper h3 {
  margin: 40px 0; }

.terms-wrapper h4 {
  font-size: 1em;
  color: #000;
  text-transform: uppercase;
  margin: 40px 0 20px 0; }

.terms-wrapper h6 {
  font-size: 0.9em;
  color: #8C8C8C;
  text-transform: uppercase;
  margin: 20px 0; }

.terms-wrapper p {
  color: #8C8C8C; }

/* privacy styling */
.privacy-wrapper h2,
.privacy-wrapper h3 {
  margin: 40px 0 30px 0;
  font-size: 2.82em;
  text-transform: none; }

.privacy-wrapper h4 {
  font-size: 1em;
  color: #000;
  text-transform: uppercase;
  margin: 40px 0 20px 0; }

.privacy-wrapper h6 {
  font-size: 0.9em;
  color: #8C8C8C;
  text-transform: uppercase;
  margin: 20px 0; }

.privacy-wrapper p {
  color: #8C8C8C;
  margin-top: 10px; }

.privacy-wrapper ul {
  color: #8C8C8C;
  line-height: 1.5em;
  -webkit-padding-start: 30px;
          padding-inline-start: 30px; }
  .privacy-wrapper ul li {
    margin-bottom: 10px; }

.privacy-wrapper ol {
  color: #8C8C8C;
  line-height: 1.5em;
  -webkit-padding-start: 30px;
          padding-inline-start: 30px; }
  .privacy-wrapper ol li {
    margin-bottom: 10px; }

.privacy-wrapper .subnav-container {
  width: 100%;
  overflow-x: auto;
  display: flex;
  white-space: nowrap; }

.privacy-wrapper .link {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  display: inline-flex;
  width: 180px;
  padding: 15px;
  margin-right: 10px;
  text-align: center;
  background: #f9f9f9;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.025em;
  border-radius: 25px;
  justify-content: center; }

.privacy-wrapper .link:hover {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  color: #0D4753 !important;
  cursor: pointer; }

.privacy-wrapper .link.active {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  background: #0D4753 !important;
  color: #fff !important;
  cursor: pointer; }

#main-window {
  height: 100%; }

.centered-text {
  text-align: center; }

.right-text {
  text-align: right; }

.left-text {
  text-align: left; }

.background-image-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.card {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  background: #fff; }

.shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05); }

.background-hover-zoom {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out; }

.background-hover-zoom:hover {
  background-size: 105%; }

.circle {
  border-radius: 50%;
  overflow: hidden; }

a,
a:active,
a:hover,
a:link {
  color: #0D4753;
  text-decoration: none; }

input {
  border: 0px;
  overflow: hidden; }

.dark-transparent-input {
  color: #fff;
  background-color: rgba(0, 0, 0, 0); }

.employee-navigator-input {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25); }

input.employee-navigator-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(255, 255, 255, 0.5); }

input.employee-navigator-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.5); }

input.employee-navigator-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.5); }

input.employee-navigator-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(255, 255, 255, 0.5); }

input:focus {
  outline: -webkit-focus-ring-color auto 0px; }

.employee-navigator-input-search-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; }

.employee-navigator-input-search-icon {
  opacity: .5; }

input.dark-transparent-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff; }

input.dark-transparent-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff; }

input.dark-transparent-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff; }

input.dark-transparent-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff; }

input:focus {
  outline: -webkit-focus-ring-color auto 0px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.embed-container .react-player {
  height: auto !important;
  width: 100% !important; }

.relative {
  position: relative; }

.hero-text {
  font-size: 120px; }

.bold-text {
  font-weight: bold !important; }

.animated {
  transition: all 0.5s ease-out; }

.offset-grid {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20%; }

.offset-grid > div:nth-child(odd) {
  margin-bottom: 20%;
  margin-top: -20%; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container input {
  background-color: #f9f9f9;
  padding: 10px; }

.hover-zoom {
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out; }

.hover-zoom:hover {
  transform: scale(0.97, 0.97); }

.invert {
  filter: grayscale(1) invert(1); }

/* Mobile Styles */
.show-for-small {
  display: none; }

.hide-for-small {
  display: block; }

.center-for-small {
  text-align: inherit; }

/* Hero's for mobile */
.mobile-hero,
.mobile-hero-image,
.mobile-hero-overlay-image {
  display: none;
  position: relative; }

#page-content {
  opacity: 0; }

#page-content.active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  animation: pageIntro 0.5s; }

@keyframes pageIntro {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.privacy-table {
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-collapse: collapse; }

.privacy-table td {
  border: 1px solid #ddd;
  padding: 8px; }

.privacy-table tr:nth-child(odd) {
  background-color: #f9f9f9; }

.privacy-table-header td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left; }
  .privacy-table-header td p {
    font-weight: bold;
    color: black; }

.border-bottom {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.05); }

select {
  padding: 20px;
  padding-left: 20px;
  padding-right: 50px;
  box-sizing: border-box;
  margin: 0;
  border: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.5em;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 20px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  min-width: 200px; }

.stretch-row {
  display: flex;
  align-content: stretch; }
  .stretch-row div {
    flex: 1 1; }

.blog-editor h6 {
  font-size: 20px; }

.inverse {
  filter: invert(100%); }

.pill {
  padding: 10px;
  padding-left: 20px;
  padding-right: 15px;
  color: #fff;
  margin-right: 10px;
  display: inline-block;
  border-radius: 20px;
  margin-bottom: 10px; }
  .pill img {
    opacity: 1; }

.hover-zoom {
  transform: scale3d(1, 1, 1);
  transition: all .25s ease-in-out; }

.hover-zoom:hover {
  transform: scale3d(1.015, 1.015, 1); }

.lip {
  z-index: 10;
  position: relative;
  margin-top: -60px;
  margin-bottom: 0px; }

.product-toggle {
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
  background-color: #f4f4f4;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px; }

.company-toggle {
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
  background-color: #f4f4f4;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px; }

.product-toggle.active, .company-toggle.active {
  color: #fff;
  background-color: #0D4753; }

.toggle-icon {
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .toggle-icon .material-toggle {
    font-size: 25px; }

.hero-padding {
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 40px; }

.autosuggest-container > div:first-child {
  width: 100%;
  display: block;
  background-color: #f9f9f9;
  margin-bottom: 10px; }
  .autosuggest-container > div:first-child input {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px; }

.autosuggest-container .pill {
  display: inline-block;
  width: auto; }

.autosuggest-menu {
  background-color: #fff; }
  .autosuggest-menu .item {
    padding: 20px; }
  .autosuggest-menu .item.item-highlighted {
    background-color: #f9f9f9; }

.label {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  background-color: #f9f9f9;
  padding: 10px;
  color: #777; }

.label.primary-background.white {
  background-color: #0D4753;
  color: #fff; }

.admin-hero-padding {
  padding-top: 80px;
  padding-bottom: 80px; }

.list-view-active .list-view-disabled-state {
  display: none; }

.list-view-active .list-view-active-state {
  display: block; }

.list-view-active .grid-view-disabled-state {
  display: block; }

.list-view-active .grid-view-active-state {
  display: none; }

.product-list-list-view-item .tiny-padding {
  padding-top: 5px;
  padding-bottom: 5px; }

.grid-view-active .grid-view-disabled-state {
  display: none; }

.grid-view-active .grid-view-active-state {
  display: block; }

.grid-view-active .list-view-disabled-state {
  display: block; }

.grid-view-active .list-view-active-state {
  display: none; }

.print .company-toggle, .print .product-toggle {
  color: #999;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  padding-right: 30px; }

.print .product-toggle.active, .print .company-toggle.active {
  color: #000;
  background-color: rgba(0, 0, 0, 0); }

.grid-view-tile {
  padding: .5%; }
  .grid-view-tile .shadow {
    padding: 3%; }

.preserve-space {
  white-space: pre-wrap; }

.package-row {
  max-width: 33%;
  min-width: 33%; }

.package-row-container {
  align-items: center;
  justify-content: center; }

@media only screen and (max-width: 2000px) {
  .hero-text {
    font-size: 90px; }
  .onmail-faq.material-icons {
    font-size: 30px !important; } }

@media only screen and (max-width: 800px) {
  .package-row {
    max-width: 100%;
    min-width: 100%; }
  .company-toggle, .product-toggle {
    font-size: 12px; }
  .product-list-list-view-item {
    margin-top: 20px; }
  .product-list-group-label {
    width: 100% !important;
    height: 10px; }
  .bundle-included-tag {
    width: 100%;
    margin-left: 10px !important;
    text-align: left; }
  .design-site-mobile-hero-padding {
    padding-top: 150px;
    padding-left: 10px;
    padding-right: 10px; }
  .center-for-small {
    text-align: center !important; }
  .no-mobile-top-padding {
    padding-top: 0; }
  .mobile-top-padding {
    padding-top: 60px; }
  .hide-for-small {
    display: none; }
  .show-for-small {
    display: block; }
  .first-for-small {
    order: -1; }
  .last-for-small {
    order: 10; }
  .mobile-hero {
    display: block;
    width: 100vw;
    height: 100vh; }
  .mobile-hero-image {
    display: block;
    width: 100vw;
    height: 70vh; }
    .mobile-hero-image .mobile-hero-overlay {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
      min-height: 50vh;
      display: flex;
      align-items: left;
      text-align: left; }
      .mobile-hero-image .mobile-hero-overlay h1 {
        font-size: 58px; }
    .mobile-hero-image .mobile-hero-overlay-color {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      opacity: 0.6; }
    .mobile-hero-image .mobile-hero-background-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center; }
  .mobile-hero-overlay-image {
    display: block;
    position: relative;
    z-index: 3;
    margin-top: -10vh;
    justify-content: flex-end; }
    .mobile-hero-overlay-image img {
      width: 45vw; }
  .mobile-hero-overlay-image.right-text {
    text-align: right !important; }
  .simple-mobile-page .large-padding {
    padding: 20px; }
  .material-icons {
    font-size: 36px !important; }
  .hero-text {
    font-size: 40px; }
  .offset-grid > div:nth-child(odd) {
    margin-bottom: 5%;
    margin-top: 0%; }
  /* terms and conditions styling */
  .terms-wrapper h3 {
    margin: 20px 0; }
  .terms-wrapper h4 {
    font-size: 1em;
    color: #000;
    text-transform: uppercase;
    margin: 20px 0 12px 0; }
  .terms-wrapper p {
    color: #8C8C8C; }
  /* privacy styling */
  .privacy-wrapper h2,
  .privacy-wrapper h3 {
    margin: 20px 0 15px 0;
    font-size: 1.82em;
    text-transform: none; }
  .privacy-wrapper h4 {
    font-size: 1em;
    color: #000;
    text-transform: uppercase;
    margin: 20px 0 12px 0; }
  .privacy-wrapper h6 {
    font-size: 0.9em;
    color: #8C8C8C;
    text-transform: uppercase;
    margin: 20px 0; } }

.printable-coverage-summary {
  padding: 20px;
  padding-top: 80px;
  width: 1200px; }
  .printable-coverage-summary h6 {
    margin-top: 20px;
    margin-bottom: 5px; }
  .printable-coverage-summary .bundle-included-tag .subheading {
    opacity: .8; }

@media print {
  #page-content.active {
    height: auto !important; }
  .page-break {
    page-break-after: always; }
  /* page-break-after works, as well */
  .prod-meta {
    page-break-inside: avoid;
    break-inside: avoid; }
  .bundle-included-tag {
    margin-left: 0px !important;
    margin-right: 2px !important;
    font-size: 12px;
    align-items: flex-start; }
  .medium-4 {
    width: 33.3%;
    padding: 2px; }
  .medium-6 {
    width: 50%; }
    .medium-6 h6 {
      margin-bottom: 0px; }
    .medium-6 h5 {
      margin: 0px !important;
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
      line-height: .5em !important;
      padding-top: 40px !important; }
  .header-container {
    position: relative !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); }
  .printable-coverage-summary {
    padding-top: 0px !important;
    margin-top: -20px; } }

.header-container {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
  transition: all .25s ease-in-out;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0; }

.edison-header-padding {
  padding: 14px; }

.header-container.active {
  transition: all .25s ease-in-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.header-container .header-logo {
  width: 140px; }

.design-header-container .header-logo {
  width: 120px;
  margin-top: 5px; }

.design-header-container .design-header-logo-container {
  display: inline-block;
  margin-left: 20px; }

.design-header-container .menu {
  float: right; }
  .design-header-container .menu .menu-item {
    margin: 20px;
    color: #fff;
    transition: all .25s ease-in-out;
    display: inline-block;
    opacity: .75; }
  .design-header-container .menu .menu-item:hover {
    color: #fff; }
  .design-header-container .menu .active .menu-item {
    color: #fff; }

.design-header-container.light .header-logo.dark {
  display: none; }

.design-header-container.light .header-logo.light {
  display: block;
  opacity: 1; }

.design-header-container.light .material-icons.dark {
  display: none; }

.design-header-container.light .material-icons.light {
  display: block;
  opacity: 1; }

.design-header-container .header-logo.dark, .design-header-container.active .header-logo.dark, .design-header-container.light.active .header-logo.dark {
  display: block; }

.design-header-container .header-logo.light, .design-header-container.active .header-logo.light, .design-header-container.light.active .header-logo.light {
  display: none; }

.design-header-container .material-icons.dark, .design-header-container.active .material-icons.dark, .design-header-container.light.active .material-icons.dark {
  display: block; }

.design-header-container .material-icons.light, .design-header-container.active .material-icons.light, .design-header-container.light.active .material-icons.light {
  display: none; }

.design-header-container .header-logo.dark {
  display: none; }

.design-header-container .header-logo.light {
  display: block; }

.menu-icon-mobile {
  float: right;
  margin: 7px 10px 0px 0px; }
  .menu-icon-mobile .material-icons {
    opacity: .3;
    transition: all .25s ease-in-out; }

.menu-icon-mobile:hover .material-icons, .menu-icon-mobile:active .material-icons {
  opacity: 1;
  transition: all .25s ease-in-out; }

.design-header-container.active .menu .menu-item {
  color: #8C8C8C; }

.design-header-container.active .header-cta {
  opacity: 1;
  transform: translateY(0%); }

.design-header-container.active .design-header-logo-container {
  display: inline-block; }

@media only screen and (max-width: 800px) {
  .design-header-container .menu {
    display: none; }
  .design-header-container .header-cta {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateY(-10%); }
  .design-header-container .header-cta a {
    padding: 10px 30px; }
  .design-header-logo-container {
    display: inline-block;
    margin-left: 0px !important; }
  .design-header-container .design-header-logo-container {
    display: flex;
    align-items: center;
    justify-content: center; } }


.product-dashboard-header-container .header-logo {
  width: 120px;
  margin-top: 5px; }

.product-dashboard-header-container .product-dashboard-header-logo-container {
  display: inline-block !important; }

.product-dashboard-header-container .menu {
  float: right; }
  .product-dashboard-header-container .menu .menu-item {
    margin: 20px;
    color: #fff;
    transition: all .25s ease-in-out;
    display: inline-block; }
  .product-dashboard-header-container .menu .menu-item:hover {
    color: #fff; }

.active .product-dashboard-header-container .menu .menu-item {
  color: #222; }

.menu-icon-mobile {
  float: right;
  margin: 7px 10px 0px 0px; }
  .menu-icon-mobile .material-icons {
    opacity: .3;
    transition: all .25s ease-in-out; }

.menu-icon-mobile:hover .material-icons, .menu-icon-mobile:active .material-icons {
  opacity: 1;
  transition: all .25s ease-in-out; }

.design-header-container.active .header-cta {
  opacity: 1;
  transform: translateY(0%); }

.header-container.active .search-bar .dark-transparent-input:-ms-input-placeholder {
  color: #666; }

.header-container.active .search-bar .dark-transparent-input::placeholder {
  color: #666; }

.header-container.active .search-bar .material-icons {
  color: #666 !important; }

.header-container.active .search-bar .search-bar-background {
  background-color: #f1f1f1 !important; }

.coverage-summary-print-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px; }

@media only screen and (max-width: 800px) {
  .design-site-mobile-hero-padding {
    padding-top: 120px;
    padding-bottom: 30px; }
  .product-dashboard-header-container .search-bar {
    width: 100%;
    padding: 10px; }
    .product-dashboard-header-container .search-bar .material-icons {
      font-size: 24px !important; }
    .product-dashboard-header-container .search-bar .dark-transparent-input {
      font-size: 16px; }
  .product-dashboard-header-container .menu {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center; }
    .product-dashboard-header-container .menu .menu-item {
      margin: 10px; }
  .product-dashboard-header-container .product-dashboard-header-logo-container {
    display: flex !important;
    align-items: center;
    justify-content: center; }
  .product-dashboard-header-container .header-cta {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateY(-10%); }
  .product-dashboard-header-container .header-cta a {
    padding: 10px 30px; } }

/* Hero Styling */
.modal-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 20000000; }

.modal-foreground-wrapper {
  z-index: 20000002;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }

.modal-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  z-index: 20000001; }

.modal {
  margin: auto;
  z-index: 20000002;
  width: 100vw;
  max-height: 100vh;
  overflow: scroll;
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }

.modal::-webkit-scrollbar {
  display: none; }

/* Hide scrollbar for IE, Edge and Firefox */
.modal {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }

.modal-content {
  position: relative;
  z-index: 20000003;
  pointer-events: auto;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh; }

.modal-content::-webkit-scrollbar {
  display: none; }

.modal-content.full-screen-override {
  max-width: none !important;
  width: 100vw;
  padding: 20px; }

/* Hide scrollbar for IE, Edge and Firefox */
.modal-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }

.modal-container .modal-background {
  background-color: rgba(0, 0, 0, 0.75); }

.modal-container .modal {
  opacity: 1;
  transform: translateY(0px); }

.modal-container.animate-out .modal-background {
  background-color: rgba(0, 0, 0, 0); }

.modal-container.animate-out .modal {
  opacity: 0;
  transform: translateY(40px); }

.modal-container.animate-in .modal-background {
  background-color: rgba(0, 0, 0, 0); }

.modal-container.animate-in .modal {
  opacity: 0;
  transform: translateY(40px); }

.portal-modal-close-icon {
  position: fixed;
  z-index: 10000002;
  top: 40px;
  right: 40px;
  cursor: pointer; }

@keyframes darkBackgroundAnimation {
  from {
    background-color: rgba(0, 0, 0, 0); }
  to {
    background-color: rgba(0, 0, 0, 0.25); } }

@keyframes lightBackgroundAnimation {
  from {
    background-color: rgba(255, 255, 255, 0); }
  to {
    background-color: rgba(255, 255, 255, 0.25); } }

@keyframes foregroundAnimation {
  from {
    opacity: 0;
    transform: translateY(20px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@media only screen and (max-width: 800px) {
  .modal-background.active {
    font-size: 50px; }
  .modal-content {
    max-height: 80vh; }
  .portal-modal-close-icon {
    top: 20px;
    z-index: 20000004;
    right: 20px;
    position: absolute;
    cursor: pointer; }
    .portal-modal-close-icon img {
      width: 30px;
      height: 30px; } }

.text-over-image-block {
  position: relative;
  width: 100%;
  height: 100%; }
  .text-over-image-block .image-block-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    min-height: 50vh;
    display: flex;
    align-items: center; }
    .text-over-image-block .image-block-overlay h3, .text-over-image-block .image-block-overlay p {
      color: #fff; }
  .text-over-image-block .image-block-overlay-color {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1; }
  .text-over-image-block .image-block-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-position: center; }

/* Hero Styling */
.image-upload-container {
  position: relative; }

.image-delete-icon {
  position: absolute;
  z-index: 10;
  top: -20px;
  right: -20px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer; }

.image-upload-container .image-upload-container-upload-button-container {
  opacity: 0;
  transition: all .25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.image-upload-container .button {
  color: #fff; }

.image-upload-container:hover .image-upload-container-upload-button-container {
  opacity: 1; }

.editable .blog-image-wrapper-container {
  position: relative; }

.editable .blog-image-upload-hover-container {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center; }

.editable .editable-delete {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px; }

.editable .editable-header {
  position: relative;
  text-align: right;
  padding: 10px;
  height: 50px;
  background-color: #fff; }

.editable .tox-tinymce {
  border: 0; }

.report-edit-page .report-chart-section {
  margin-top: 50px;
  background-color: #fff; }
  .report-edit-page .report-chart-section input {
    font-size: 30px;
    border: 1px solid #d9d9d9;
    background-color: #fff; }
  .report-edit-page .report-chart-section .note input {
    font-size: 15px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    color: #999; }

.report-edit-page .report-title-section {
  margin-top: 50px;
  background-color: #fff; }
  .report-edit-page .report-title-section input {
    font-size: 50px;
    border: 1px solid #d9d9d9;
    background-color: #fff; }

.report-edit-page .report-text-section {
  margin-top: 50px; }

.report-edit-page .report-image-section {
  margin-top: 50px; }

.blog-content p, .blog-content span, .blog-content a, .blog-content li, .blog-content ol, .blog-content ul {
  font-size: 20px !important;
  line-height: 1.75 !important; }

.blog-back {
  position: fixed;
  left: 30px;
  top: 0px;
  width: 60px;
  height: 60px;
  padding: 7px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  cursor: pointer; }

.section-margin {
  margin-top: 100px; }

.image-background-preload {
  width: 100%; }

@media only screen and (max-width: 800px) {
  .blog-back {
    position: relative;
    left: 10px;
    top: 60px;
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-bottom: 30px; }
  .blog-content {
    padding: 10px; } }

/* Hero Styling */
.image-upload-container {
  position: relative; }

.image-upload-container .image-upload-container-upload-button-container {
  opacity: 0;
  transition: all .25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.image-upload-container .button {
  color: #fff; }

.image-upload-container:hover .image-upload-container-upload-button-container {
  opacity: 1; }

.full-page-break-container {
  height: 100vh;
  width: 100vw;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex; }

.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7em; }

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  padding-top: 3em;
  padding-bottom: 3em; }

.logoTextContainer {
  display: flex;
  padding-top: 3em; }

.downloadText {
  font-weight: bold; }

.logo {
  height: 60px; }

.improperContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap; }

.improperContainer::after {
  content: '';
  flex: auto; }

.improperLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5em; }

.improperLogo {
  height: 4em;
  margin: 2em;
  margin-top: 4em; }

.logoExample {
  width: 100%;
  object-fit: contain; }

.design-dashboard-container {
  display: flex;
  height: 100%;
  overflow: hidden; }

.design-dashboard-navigation {
  width: 300px;
  position: relative; }

.design-dashboard-navigation-item {
  padding: 15px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0); }

.design-dashboard-navigation-item.active {
  background-color: rgba(255, 255, 255, 0.15); }

.design-dashboard-navigation-item a {
  color: rgba(255, 255, 255, 0.75); }

.design-dashboard-navigation-item.active a {
  color: white; }

.design-dashboard-navigation-logo {
  width: 150px;
  padding: 15px; }

.design-dashboard-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll; }

.design-dashboard-mobile-menu-icon {
  height: 60px;
  width: 60px;
  position: absolute;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  padding: 10px;
  display: none; }

.design-dashboard-navigation-anchor {
  position: absolute;
  padding: 10px;
  bottom: 0px;
  left: 0px;
  width: 100%; }

.design-dashboard-navigation-anchor a {
  background-color: #ABD0D9;
  padding: 20px;
  width: 100%;
  display: inline-block; }

@media only screen and (max-width: 800px) {
  .design-dashboard-navigation {
    position: absolute;
    height: 100%;
    left: -300px;
    transition: all .15s ease-out;
    z-index: 10000; }
  .mobile-menu-open .design-dashboard-navigation {
    left: 0px; }
  .design-dashboard-mobile-menu-icon {
    display: block; } }

