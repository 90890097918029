/* Hero Styling */
.image-upload-container {
  position: relative; }

.image-delete-icon {
  position: absolute;
  z-index: 10;
  top: -20px;
  right: -20px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer; }

.image-upload-container .image-upload-container-upload-button-container {
  opacity: 0;
  transition: all .25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.image-upload-container .button {
  color: #fff; }

.image-upload-container:hover .image-upload-container-upload-button-container {
  opacity: 1; }
