.header-container {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
  transition: all .25s ease-in-out;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0; }

.edison-header-padding {
  padding: 14px; }

.header-container.active {
  transition: all .25s ease-in-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.header-container .header-logo {
  width: 140px; }
