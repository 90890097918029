.report-edit-page .report-chart-section {
  margin-top: 50px;
  background-color: #fff; }
  .report-edit-page .report-chart-section input {
    font-size: 30px;
    border: 1px solid #d9d9d9;
    background-color: #fff; }
  .report-edit-page .report-chart-section .note input {
    font-size: 15px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    color: #999; }

.report-edit-page .report-title-section {
  margin-top: 50px;
  background-color: #fff; }
  .report-edit-page .report-title-section input {
    font-size: 50px;
    border: 1px solid #d9d9d9;
    background-color: #fff; }

.report-edit-page .report-text-section {
  margin-top: 50px; }

.report-edit-page .report-image-section {
  margin-top: 50px; }
