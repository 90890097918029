.editable .blog-image-wrapper-container {
  position: relative; }

.editable .blog-image-upload-hover-container {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center; }

.editable .editable-delete {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px; }

.editable .editable-header {
  position: relative;
  text-align: right;
  padding: 10px;
  height: 50px;
  background-color: #fff; }

.editable .tox-tinymce {
  border: 0; }
