.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7em; }

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  padding-top: 3em;
  padding-bottom: 3em; }

.logoTextContainer {
  display: flex;
  padding-top: 3em; }

.downloadText {
  font-weight: bold; }

.logo {
  height: 60px; }

.improperContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap; }

.improperContainer::after {
  content: '';
  flex: auto; }

.improperLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5em; }

.improperLogo {
  height: 4em;
  margin: 2em;
  margin-top: 4em; }

.logoExample {
  width: 100%;
  object-fit: contain; }
