.blog-content p, .blog-content span, .blog-content a, .blog-content li, .blog-content ol, .blog-content ul {
  font-size: 20px !important;
  line-height: 1.75 !important; }

.blog-back {
  position: fixed;
  left: 30px;
  top: 0px;
  width: 60px;
  height: 60px;
  padding: 7px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  cursor: pointer; }

.section-margin {
  margin-top: 100px; }

.image-background-preload {
  width: 100%; }

@media only screen and (max-width: 800px) {
  .blog-back {
    position: relative;
    left: 10px;
    top: 60px;
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-bottom: 30px; }
  .blog-content {
    padding: 10px; } }
