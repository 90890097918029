.design-dashboard-container {
  display: flex;
  height: 100%;
  overflow: hidden; }

.design-dashboard-navigation {
  width: 300px;
  position: relative; }

.design-dashboard-navigation-item {
  padding: 15px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0); }

.design-dashboard-navigation-item.active {
  background-color: rgba(255, 255, 255, 0.15); }

.design-dashboard-navigation-item a {
  color: rgba(255, 255, 255, 0.75); }

.design-dashboard-navigation-item.active a {
  color: white; }

.design-dashboard-navigation-logo {
  width: 150px;
  padding: 15px; }

.design-dashboard-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll; }

.design-dashboard-mobile-menu-icon {
  height: 60px;
  width: 60px;
  position: absolute;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  padding: 10px;
  display: none; }

.design-dashboard-navigation-anchor {
  position: absolute;
  padding: 10px;
  bottom: 0px;
  left: 0px;
  width: 100%; }

.design-dashboard-navigation-anchor a {
  background-color: #ABD0D9;
  padding: 20px;
  width: 100%;
  display: inline-block; }

@media only screen and (max-width: 800px) {
  .design-dashboard-navigation {
    position: absolute;
    height: 100%;
    left: -300px;
    transition: all .15s ease-out;
    z-index: 10000; }
  .mobile-menu-open .design-dashboard-navigation {
    left: 0px; }
  .design-dashboard-mobile-menu-icon {
    display: block; } }
